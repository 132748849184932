.home {
  min-height: 100vh;
  flex-direction: column;
  margin: 0 4%;
}

.home-highlights-text {
  color: $green;
  font: $medium-bold-font;
  margin-top: $m-margin;
}

.search-form {
  .search-form-input-control {
    border-radius: 0;
    border: 1px solid $black;
    border-left: 0;
    padding-left: 0;
  }

  ::placeholder {
    color: $gray;
    font: $medium-font;
  }

  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .input-group {
    width: 100%;

    .input-group-text {
      border-radius: 0;
      border: 1px solid $black;
      border-right: 0;
      background: $white;
    }
  }
}

.home-highlights-body {
  margin: 0 8%;
  text-align: left;
}

.home-highlights-separator {
  width: 84%;
  border-top: 1px solid $medium-gray;
}

.card-columns {
  .card-text {
    margin: $xxs-margin 0;
  }
}

.dataset-card-column {
  padding: 0;
}

.dataset-card-container {
  border: none;
  padding-bottom: $xxs-padding;

  .dataset-card-body {
    padding-left: 0;
    padding-top: 0;
  }

  .dataset-card-body-title {
    font: $medium-bold-font;

    a {
      color: $black;
    }

    div {
      font: $small-font;

      span.Updated {
        color: $green;
      }

      span.Pending {
        color: $yellow;
      }
    }
  }

  .dataset-card-body-metadata {
    color: $dark-gray;
  }
}
