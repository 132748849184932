@import 'main.scss';
@import 'colors.scss';
@import 'fonts.scss';
@import 'margins.scss';
@import 'paddings.scss';

// Overrides
@import 'overrides/button.scss';
@import 'overrides/dropdown.scss';
@import 'overrides/navbar.scss';
@import 'overrides/pagination.scss';
@import 'overrides/tabs.scss';
@import 'overrides/modal.scss';
@import 'overrides/alert.scss';

// Custom styles
@import 'header.scss';
@import 'footer.scss';
@import 'home.scss';
@import 'about.scss';
@import 'search.scss';
@import 'dataset.scss';
@import 'not-found.scss';
@import 'login.scss';
@import 'signup.scss';
@import 'password.scss';
@import 'code-verification.scss';
@import 'data-curator-tool.scss';
@import 'modal.scss';
@import 'button.scss';
@import 'ahead-table.scss';
@import 'loading';

body {
  margin: 0;
  font: $small-spaced-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.brand-logo {
  height: 90px;
  margin-top: $xs-margin;
}
