.dropdown {
  display: inline;
}

.dropdown-toggle::after {
  margin-left: $xs-margin;
}

.dropdown-item {
  font: $small-font;
}
