.alert-success {
  background-color: $green;
  color: $white;

  span {
    color: $green;
    text-shadow: none;
  }

  :hover {
    cursor: pointer;
  }
}

.alert-info {
  background-color: $yellow;
  color: $black;

  :hover {
    cursor: pointer;
  }
}

.alert-danger {
  background-color: $white;
  color: $red;

  :hover {
    cursor: pointer;
  }
}
