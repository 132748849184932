.ahead-modal {
  .ahead-modal-close {
    margin-left: 0;
  }

  .ahead-modal-content {
    margin: 0 $l-margin $s-margin $l-margin;

    .ahead-modal-content-title {
      margin: 0;
      padding-bottom: $s-margin;

      h1 {
        margin: 0;
        font: $x-large-bold-font;
      }
    }

    .ahead-modal-content-body {
      margin: 0;
      padding-bottom: $m-margin;
      padding-right: $xxl-margin;
      font: $medium-font;
    }

    .ahead-modal-content-signature {
      margin: 0;
      font: $small-bold-font;
    }

    .ahead-modal-content-image {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .ahead-modal-action-buttons {
    margin: 0 $l-margin $s-margin $l-margin;
    align-items: center;

    .btn {
      margin-top: $s-margin;

      &:first-child {
        margin-right: $s-margin;
      }
    }

    span {
      margin-top: $s-margin;
      font: $small-font;
      color: $green;
    }
  }

  .ahead-modal-action-close-editor-download,
  .ahead-modal-action-cancel {
    margin: 0 $s-margin;
  }
}
