.page-link {
  color: $black;
  border: none;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0.5rem;
  margin-right: 1px;
  border-right: 1px solid $gray;

  &:hover {
    border-right: 1px solid $gray;
  }
}

.page-item:last-child .page-link {
  border: none;
}

.pagination-page-button.page-item.active {
  .page-link {
    background-color: $green;
  }
}
