.loading-overlay-modal {
  .modal-content {
    background: transparent;
    border-width: 0;
  }
}

.loading-spinner {
  .row {
    margin: 0;
  }

  h1 {
    margin-top: 1em;
    font: $medium-bold-font;
  }
}
