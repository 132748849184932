.about {
  min-height: 100vh;
  flex-direction: column;
  margin-left: $l-margin;

  .about-content {
    margin-bottom: $m-margin;
  }

  .about-links {
    .links-list {
      margin-left: $s-margin;
      margin-bottom: $s-margin;

      .link-list-item {
        a {
          text-decoration: underline;
          color: $black;
          font: $small-bold-font;
        }
      }
    }
  }

  .post-header {
    margin-bottom: $m-margin;

    .post-header-title {
      text-align: left;

      h1 {
        margin: 0;
        font: $x-large-bold-font;
      }
    }
  }

  .post-body {
    font: $small-font;
    text-align: left;
    margin-right: $m-margin;
  }
}
