.header {
  font: $medium-font;

  .navbar {
    padding: $xxs-padding !important;
    width: 100%;
  }

  .navbar-collapse {
    height: 100%;
    align-items: normal;
  }
}
