.react-tabs__tab {
  border-radius: 0;
  border: 1px solid transparent;
  font: $small-font;
  bottom: 0;
}

.react-tabs__tab.btn-light {
  display: inline-block;

  &:focus {
    box-shadow: none;
    border-color: transparent;
    background: white;
    color: $green;
  }

  &:focus::after {
    background: transparent;
  }

  &:hover,
  &:not(:disabled):not(:disabled):active {
    color: $gray;
    background-color: $white;
    border: 1px solid $white;
    text-decoration: underline $gray;
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.react-tabs__tab--selected.btn-light {
  color: $green;
  font-weight: bold;
  background-color: transparent;

  &:hover,
  &:not(:disabled):not(:disabled):active {
    color: $green;
    border: 1px solid $white;
    text-decoration: none;
  }
}

.react-tabs__tab-list {
  text-align: left;
  padding-bottom: 6px;

  p {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    position: relative;
    list-style: none;
    padding: $xxs-padding $xs-padding;
    cursor: pointer;
  }
}
