$yellow: #f9c941;
$orange: #f97500;
$green: #0f8517;
$light-green: #5fc706;
$dark-green: #095a1f;
$black: #000;
$white: #fff;
$dark-gray: #757575;
$medium-gray: #757575;
$gray: #757575;
$light-gray: #efefef;
$red: #ea1010;
