.ahead-table {
  width: 100%;

  .ahead-table-cell-modified {
    background: $yellow;
  }
}

.ahead-table-modified {
  width: 100%;
  margin-top: $xs-margin;
  margin-bottom: $s-margin;

  .ahead-table-cell-modified {
    background: $yellow;
  }

  select {
    border: transparent;
    background: transparent;
    margin: 0;
    padding: 0;
  }
}
