.button-with-icon {
  color: $black;
  text-align: center;
  font: $small-font;

  img {
    padding: 0;
    margin-right: $xxs-margin;
    vertical-align: center;
    width: 20px;
  }

  .img {
    display: inline-block;
  }

  .img-hover {
    display: none;
  }

  &:hover {
    color: $green;
    text-decoration: none;
    transition: 0s;

    .img {
      display: none;
    }

    .img-hover {
      display: inline-block;
    }
  }
}

.dataset-back-button-container {
  padding: 0;
  margin: 0;
}
