.signup {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;

  h1 {
    margin: 0;
    font: $medium-bold-font;
  }
}
