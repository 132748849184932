.not-found {
  min-height: 100vh;
  flex-direction: column;
  text-align: left;
  margin: 4%;
  font: $x-large-bold-font;

  h1 {
    margin: 0;
    font: $x-large-bold-font;
  }
}
