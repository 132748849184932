.data-curator-tool {
  min-height: 100vh;

  .data-curator-tool-dataset-name-container {
    padding-bottom: $xs-padding;
    text-align: center;
    align-items: center;

    h1 {
      margin: 0;
      font: $medium-bold-font;
    }
  }

  .data-curator-tool-tables-container {
    border-top: 1px solid $medium-gray;
    min-height: 100vh;
  }

  .row {
    margin: 0;
  }

  .data-curator-tool-filename {
    font: $small-bold-font;
    margin-bottom: $s-margin;
  }

  .data-curator-tool-download-row {
    margin-top: $l-margin;
  }

  button {
    font: $small-font;
  }

  .alert {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;

    p {
      margin-bottom: 0;
    }
  }

  .ahead-table-dataset-actions {
    border-bottom: 1px solid $gray;
    padding-bottom: $xxs-padding;

    p {
      margin-top: $xs-margin;
      margin-bottom: 0;
      text-align: right;
    }

    .dataset-changes-col {
      padding-right: 0;
    }

    .dataset-changes {
      color: $black;
      font-weight: bold;
    }

    .dataset-no-changes {
      color: $dark-gray;
    }
  }
}

.close-editor-button {
  font: $small-font;
  margin-top: $s-margin;
  margin-bottom: $s-margin;

  .close-editor-button-cross-icon {
    width: 9px;
    height: 9px;
    margin-right: $xxs-margin;
    margin-bottom: $xxs-margin;
  }
}

.save-dataset-changes-button {
  margin-left: $s-margin;
}

.delete-dataset-changes-button,
.save-dataset-changes-button,
.load-latest-dataset-changes-button {
  margin-bottom: 2px;
}

.refresh-images-button {
  margin-top: $xs-margin;
}

.ahead-table-column {
  padding: 0 $m-padding;
}

.ahead-tabs-divider {
  height: 20px;
  padding: 0 $xxs-padding;
}

.ahead-column-number {
  white-space: pre;
  vertical-align: middle;
  color: $black;
  padding: 0 $m-padding;
}

.ahead-data-curator-image-container {
  height: 70vh;
  overflow: scroll;
  padding-right: $xxs-padding;
}

.ahead-complete-image-link {
  color: $green;
  display: inline-block;
  float: right;

  &:hover {
    color: $green;
  }
}

.ahead-data-curator-image-container::-webkit-scrollbar {
  width: 6px;
}

.ahead-data-curator-image-container::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: $yellow;
}

.ahead-table-dropdown-modified {
  background-color: $yellow !important;
}

@media screen and (min-width: 768px) {
  .data-curator-tool-actions {
    height: 80px;
  }

  .ahead-table-left {
    border-right: 1px solid $black;
  }

  .ahead-complete-image-link {
    display: inline-block;
    float: unset;
  }
}

@media screen and (min-width: 1366px) {
  .ahead-complete-image-link {
    display: inline-block;
    float: right;
  }
}
