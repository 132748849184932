.dataset {
  min-height: 100vh;
  margin: $m-margin 5%;

  .dataset-header-title {
    text-align: left;
    margin-bottom: $s-margin;

    h1 {
      margin: 0;
      font: $medium-bold-font;
    }
  }

  .dataset-description {
    margin-bottom: $s-margin;
    font: $small-spaced-font;
    text-align: left;
  }

  .divider {
    margin: 0 $xs-margin;
  }

  .dataset-metadata {
    align-items: center;

    #dropdown-versions-button {
      font-weight: bold;
    }

    .dataset-metadata-version {
      font: $small-bold-font;
      margin-right: $xs-margin;
    }

    .dataset-metadata-version-dropdown {
      .dataset-versions-dropdown-spinner-container {
        text-align: center;
      }

      .dataset-versions-dropdown-menu {
        max-height: 30vh;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 1px;
          background: $green;
        }
      }
    }

    .dataset-metadata-date,
    .dataset-metadata-number-of-files,
    .dataset-metadata-keywords {
      font: $small-font;
      color: $dark-gray;
    }

    .dataset-metadata-metrics {
      font: $small-bold-font;
    }
  }

  .dataset-open-dataverse {
    margin-top: $xs-margin;
    margin-bottom: $xs-margin;

    a {
      color: $black;
      font: $small-font;
      margin-left: $xxs-margin;
    }
  }

  .search-file-form {
    width: 30%;
    margin-left: $xxs-margin;

    .search-file-form-input-control {
      border-radius: 0;
      border: 1px solid $black;
      border-left: 0;
      padding-left: 0;
    }

    ::placeholder {
      color: $gray;
      font: $medium-font;
    }

    .form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .input-group {
      width: 100%;

      .input-group-text {
        border-radius: 0;
        border: 1px solid $black;
        border-right: 0;
        background: $white;
      }
    }
  }

  .dataset-file-list-group {
    margin-top: $xs-margin;

    .dataset-file-list-item {
      padding: $xxs-padding 0 $xxs-padding $xxs-padding;

      &:first-child {
        border-top: 1px solid $medium-gray;
      }

      &:nth-child(odd) {
        background-color: $light-gray;
      }

      align-items: center;

      .dataset-file-list-item-filename {
        text-align: left;

        button {
          padding: 0;
          margin: 0;
          font: $small-font;
        }

        button:hover {
          text-decoration: underline;
          text-decoration-color: $green;
          cursor: pointer;
        }

        span {
          color: $green;
        }
      }

      .dataset-file-list-item-edit-button {
        text-align: right;
      }

      .dataset-file-list-item-download-button {
        text-align: right;
      }
    }
  }

  .dataset-files-pagination {
    ul {
      margin-top: 0.3rem;
      margin-bottom: 0.7rem;
    }

    .page-number-jump-input {
      max-width: 160px;
      border-radius: 0;
      border: 1px solid #000;
    }

    ::placeholder {
      color: gray;
    }

    .form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
