.btn-primary {
  background-color: $green;
  border-color: $green;
  color: white;
  border-radius: 1px;

  &:hover {
    background-color: $light-green;
    border-color: $light-green;
  }

  &:not(:disabled):not(:disabled):active {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.btn-secondary {
  background-color: $white;
  border-color: $green;
  color: $green;
  border-radius: 1px;

  &:hover {
    background-color: $light-green;
    border-color: $light-green;
  }

  &:disabled,
  &:disabled:hover {
    background-color: $gray;
    border-color: $gray;
  }

  &:not(:disabled):not(:disabled):active {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.btn-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  color: $black;
}

.btn-link:hover,
.btn-link:focus {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: underline;
}

.btn-sm {
  padding: 0;
}

.btn-light {
  border: 1px solid $black;
  border-radius: 0;
  display: table;
  background-color: $white;

  &:focus {
    box-shadow: none;
  }

  &:hover,
  &:not(:disabled):not(:disabled):active {
    color: $white;
    background-color: $black;
    border: 1px solid $black;
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
