.search {
  min-height: 100vh;
  flex-direction: column;
  margin-left: $l-margin;

  .row {
    width: 100%;
    position: relative;
  }

  .search-input {
    margin-top: 1.5em;
  }

  .search-options-results {
    text-align: left;
    padding: $xs-padding 0;

    .checkbox-group {
      display: inline;
    }

    .checkbox-group-icon {
      width: 15px;
    }

    img {
      margin: 0 $xs-margin;
    }

    span {
      position: relative;
    }

    .sort-dropdown {
      button {
        padding: 0;
        margin-bottom: $xxs-margin;
      }

      .dropdown-item:hover {
        background-color: $yellow;
      }
    }

    .divider-line {
      margin-bottom: $xxs-margin;
    }
  }

  .search-results-body {
    text-align: left;

    .row {
      margin: 0;
      padding: 0;
    }

    .list-group-item {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $black;

      &:nth-child(even) {
        background-color: $light-gray;
      }

      .search-list-item {
        padding: $s-padding $s-padding;

        .search-dataset-title {
          font: $medium-bold-font;

          a {
            color: $black;
          }
        }

        &:hover {
          .search-dataset-title {
            color: $green;
          }
        }

        .search-dataset-version {
          font: $small-font;
          margin-bottom: $xs-margin;

          span.Updated {
            color: $green;
            padding-left: $xxs-padding;
          }

          span.Pending {
            color: $orange;
            padding-left: $xxs-padding;
          }
        }

        .search-dataset-description {
          margin-bottom: $xxs-margin;
        }

        .search-dataset-metadata {
          color: $dark-gray;
        }
      }
    }

    .list-group-flush:last-child .list-group-item:last-child {
      border-bottom: 1px solid $black;
    }

    .list-group-flush:first-child .list-group-item:first-child {
      border-top: 1px solid $black;
    }
  }

  .search-options-datasets-count {
    color: $green;
    font: $small-bold-font;
  }

  .search-results-pagination {
    margin-top: $m-margin;
  }
}
