.footer {
  font: $small-spaced-font;
  padding-bottom: $l-padding;

  p {
    text-align: left;
    margin: 0;
  }

  .links-list-title {
    margin-bottom: $xs-margin;
  }

  .link-list-item {
    margin-bottom: $xs-margin;
  }

  .navbar {
    width: 100%;
    align-items: normal;
  }
}

.footer-col {
  margin-right: $s-margin;
}

.footer-separator {
  border-top: 1px solid $medium-gray;
  width: 95%;
}

.footer-links a {
  text-decoration: underline;
  color: $black;
  font: $small-bold-font;
}
